export const photographerConstants = {
  AUTO_REDIRECT_SECONDS: 10,
  FILE_UPLOAD: {
    ACCEPTABLE_TYPE_HEADER_REG: /^ffd8/,
    MAX_SIZE: 20000000,
    MAX_NAME_LENGTH: 100,
    MAX_COUNT: 500,
    /** 1辺の最低ピクセル数 */
    MIN_SIDE_LENGTH: 450,
    PROGRESS_RATE: {
      /** アップロード完了 */
      UPLOAD_COMPLETED: 0.9,
      /** サムネイル作成完了 */
      COMPLETED: 1,
    },
    /** アップロードステータス更新実行間隔 */
    UPLOADING_STATUS_INTERVAL: 3000,
    /** ファイルサイズ画面表示用単位（MB） */
    SIZE_UNIT: 1000000,
    /** 低速モード時の同時アップロード写真枚数 */
    SLOW_MODE_MAX_UPLOADING_PHOTO_COUNT: 1,
    /** 高速モード時の同時アップロード写真枚数 */
    FAST_MODE_MAX_UPLOADING_PHOTO_COUNT: 10,
    /** アップロード処理実行間隔 */
    UPLOADING_INTERVAL: 1000,
    /** 失敗した写真のステータスチェック処理実行間隔 */
    FAILED_PHOTOS_STATUS_INTERVAL: 10000,
  },
  FONT_FAMILY: {
    NOTO_SANS_JP: 'Noto Sans JP',
  },
  NOTIFICATIONS: {
    /** 案件依頼の表示年数 */
    DISPLAYED_YEARS: 5,
  },
  /** TOPページの表示件数 */
  TOP_DISPLAYS_NUM: 5,
  CONSUMPTION_TAX_RATE: 0.1,
} as const
